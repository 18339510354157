@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic");
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~overlayscrollbars/css/OverlayScrollbars.css';
@import '~icheck-bootstrap/icheck-bootstrap.css';
@import '~bootstrap4-toggle/css/bootstrap4-toggle.min.css';
@import '~select2/dist/css/select2.min.css';
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
.toast-title {
  font-weight: bold;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.swal2-popup.swal2-toast {
  flex-direction: column;
  align-items: stretch;
  width: auto;
  padding: 1.25em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 0.625em #d9d9d9;
}
.swal2-popup.swal2-toast .swal2-header {
  flex-direction: row;
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-title {
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 0.625em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center;
}
.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: 0.3125em auto;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}
.swal2-popup.swal2-toast .swal2-close {
  position: static;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.8;
}
.swal2-popup.swal2-toast .swal2-content {
  justify-content: flex-start;
  margin: 0 0.625em;
  padding: 0;
  font-size: 1em;
  text-align: initial;
}
.swal2-popup.swal2-toast .swal2-html-container {
  padding: 0.625em 0 0;
}
.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 0.5em 0 0;
}
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
    font-size: 0.25em;
  }
}
.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: 0.875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions {
  flex: 1;
  flex-basis: auto !important;
  align-self: stretch;
  width: auto;
  height: 2.2em;
  height: auto;
  margin: 0 0.3125em;
  margin-top: 0.3125em;
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-styled {
  margin: 0.125em 0.3125em;
  padding: 0.3125em 0.625em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.8em;
  left: -0.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.25em;
  left: 0.9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 2.6875em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: 0.1875em;
  width: 0.75em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: 0.9375em;
  right: 0.1875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-toast-animate-success-line-tip 0.75s;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-toast-animate-success-line-long 0.75s;
}
.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
}
.swal2-popup.swal2-toast.swal2-hide {
  animation: swal2-toast-hide 0.1s forwards;
}

.swal2-container {
  display: flex;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.swal2-container.swal2-backdrop-hide {
  background: transparent !important;
}
.swal2-container.swal2-top {
  align-items: flex-start;
}
.swal2-container.swal2-top-start, .swal2-container.swal2-top-left {
  align-items: flex-start;
  justify-content: flex-start;
}
.swal2-container.swal2-top-end, .swal2-container.swal2-top-right {
  align-items: flex-start;
  justify-content: flex-end;
}
.swal2-container.swal2-center {
  align-items: center;
}
.swal2-container.swal2-center-start, .swal2-container.swal2-center-left {
  align-items: center;
  justify-content: flex-start;
}
.swal2-container.swal2-center-end, .swal2-container.swal2-center-right {
  align-items: center;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom {
  align-items: flex-end;
}
.swal2-container.swal2-bottom-start, .swal2-container.swal2-bottom-left {
  align-items: flex-end;
  justify-content: flex-start;
}
.swal2-container.swal2-bottom-end, .swal2-container.swal2-bottom-right {
  align-items: flex-end;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom > :first-child, .swal2-container.swal2-bottom-start > :first-child, .swal2-container.swal2-bottom-left > :first-child, .swal2-container.swal2-bottom-end > :first-child, .swal2-container.swal2-bottom-right > :first-child {
  margin-top: auto;
}
.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-self: stretch;
  justify-content: center;
}
.swal2-container.swal2-grow-row > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}
.swal2-container.swal2-grow-column {
  flex: 1;
  flex-direction: column;
}
.swal2-container.swal2-grow-column.swal2-top, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-bottom {
  align-items: center;
}
.swal2-container.swal2-grow-column.swal2-top-start, .swal2-container.swal2-grow-column.swal2-center-start, .swal2-container.swal2-grow-column.swal2-bottom-start, .swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-bottom-left {
  align-items: flex-start;
}
.swal2-container.swal2-grow-column.swal2-top-end, .swal2-container.swal2-grow-column.swal2-center-end, .swal2-container.swal2-grow-column.swal2-bottom-end, .swal2-container.swal2-grow-column.swal2-top-right, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-bottom-right {
  align-items: flex-end;
}
.swal2-container.swal2-grow-column > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}
.swal2-container.swal2-no-transition {
  transition: none !important;
}
.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen) > .swal2-modal {
  margin: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important;
  }
}

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
}
.swal2-popup:focus {
  outline: none;
}
.swal2-popup.swal2-loading {
  overflow-y: hidden;
}

.swal2-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.8em;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1.25em auto 0;
  padding: 0;
}
.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: 0.4;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: 0.25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent;
}

.swal2-styled {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  box-shadow: none;
  font-weight: 500;
}
.swal2-styled:not([disabled]) {
  cursor: pointer;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #2778c4;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-deny {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #d14529;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #757575;
  color: #fff;
  font-size: 1em;
}
.swal2-styled:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-styled::-moz-focus-inner {
  border: 0;
}

.swal2-footer {
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em;
}

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.25em;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.swal2-timer-progress-bar {
  width: 100%;
  height: 0.25em;
  background: rgba(0, 0, 0, 0.2);
}

.swal2-image {
  max-width: 100%;
  margin: 1.25em auto;
}

.swal2-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s ease-out;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: #cccccc;
  font-family: serif;
  font-size: 2.5em;
  line-height: 1.2;
  cursor: pointer;
}
.swal2-close:hover {
  transform: none;
  background: transparent;
  color: #f27474;
}
.swal2-close:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-close::-moz-focus-inner {
  border: 0;
}

.swal2-content {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 0 1.6em;
  color: #545454;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}

.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 1em auto;
}

.swal2-input,
.swal2-file,
.swal2-textarea {
  box-sizing: border-box;
  width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  color: inherit;
  font-size: 1.125em;
}
.swal2-input.swal2-inputerror,
.swal2-file.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}
.swal2-input:focus,
.swal2-file:focus,
.swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-input::placeholder,
.swal2-file::placeholder,
.swal2-textarea::placeholder {
  color: #cccccc;
}

.swal2-range {
  margin: 1em auto;
  background: #fff;
}
.swal2-range input {
  width: 80%;
}
.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center;
}
.swal2-range input,
.swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}

.swal2-input {
  height: 2.625em;
  padding: 0 0.75em;
}
.swal2-input[type=number] {
  max-width: 10em;
}

.swal2-file {
  background: inherit;
  font-size: 1.125em;
}

.swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: 0.375em 0.625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em;
}

.swal2-radio,
.swal2-checkbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit;
}
.swal2-radio label,
.swal2-checkbox label {
  margin: 0 0.6em;
  font-size: 1.125em;
}
.swal2-radio input,
.swal2-checkbox input {
  flex-shrink: 0;
  margin: 0 0.4em;
}

.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto;
}

.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 0 -2.7em;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666666;
  font-size: 1em;
  font-weight: 300;
}
.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}
.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #f27474;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86;
}
.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee;
}
.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd;
}
.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
}
.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #a5dc86;
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: 0.8125em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}

.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 0 0 1.25em;
  padding: 0;
  background: inherit;
  font-weight: 600;
}
.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}
.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6;
}
.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: 0.4em;
  margin: 0 -1px;
  background: #2778c4;
}

[class^=swal2] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: swal2-show 0.3s;
}

.swal2-hide {
  animation: swal2-hide 0.15s forwards;
}

.swal2-noanimation {
  transition: none;
}

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.swal2-rtl .swal2-close {
  right: auto;
  left: 0;
}
.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto;
}

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}
@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes swal2-show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}
body.swal2-height-auto {
  height: auto !important;
}
body.swal2-no-backdrop .swal2-container {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  max-width: calc(100% - 0.625em * 2);
  background-color: transparent !important;
}
body.swal2-no-backdrop .swal2-container > .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
body.swal2-no-backdrop .swal2-container.swal2-top {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-top-start, body.swal2-no-backdrop .swal2-container.swal2-top-left {
  top: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-top-end, body.swal2-no-backdrop .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-start, body.swal2-no-backdrop .swal2-container.swal2-center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-end, body.swal2-no-backdrop .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-start, body.swal2-no-backdrop .swal2-container.swal2-bottom-left {
  bottom: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-end, body.swal2-no-backdrop .swal2-container.swal2-bottom-right {
  right: 0;
  bottom: 0;
}
@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important;
  }
}
body.swal2-toast-shown .swal2-container {
  background-color: transparent;
}
body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

@keyframes fadeInDown {
  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
}
.swal2-popup.swal2-toast {
  flex-direction: column;
  align-items: stretch;
  width: auto;
  padding: 0.75rem 0.25rem;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
}
.swal2-popup.swal2-toast .swal2-header {
  flex-direction: row;
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-title {
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 0.625em;
  font-size: 0.875rem;
}
.swal2-popup.swal2-toast .swal2-loading {
  justify-content: center;
}
.swal2-popup.swal2-toast .swal2-input {
  height: 2em;
  margin: 0.3125em auto;
  font-size: 0.875rem;
}
.swal2-popup.swal2-toast .swal2-validation-message {
  font-size: 0.875rem;
}
.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}
.swal2-popup.swal2-toast .swal2-close {
  position: static;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.8;
}
.swal2-popup.swal2-toast .swal2-content {
  justify-content: flex-start;
  margin: 0 0.625em;
  padding: 0;
  font-size: 0.875rem;
  text-align: initial;
}
.swal2-popup.swal2-toast .swal2-html-container {
  padding: 0.625em 0 0;
}
.swal2-popup.swal2-toast .swal2-html-container:empty {
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0 0.5em 0 0;
}
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
    font-size: 0.25em;
  }
}
.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: 0.875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions {
  flex: 1;
  flex-basis: auto !important;
  align-self: stretch;
  width: auto;
  height: 2.2em;
  height: auto;
  margin: 0 0.3125em;
  margin-top: 0.3125em;
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-styled {
  margin: 0.125em 0.3125em;
  padding: 0.3125em 0.625em;
  font-size: 0.875rem;
}
.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-popup.swal2-toast .swal2-success {
  border-color: #28a745;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.8em;
  left: -0.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.25em;
  left: 0.9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 2.6875em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: 0.1875em;
  width: 0.75em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: 0.9375em;
  right: 0.1875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-toast-animate-success-line-tip 0.75s;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-toast-animate-success-line-long 0.75s;
}
.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
}
.swal2-popup.swal2-toast.swal2-hide {
  animation: swal2-toast-hide 0.1s forwards;
}

.swal2-container {
  display: flex;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.5s;
  -webkit-overflow-scrolling: touch;
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.5);
}
.swal2-container.swal2-backdrop-hide {
  background: transparent !important;
}
.swal2-container.swal2-top {
  align-items: flex-start;
}
.swal2-container.swal2-top-start, .swal2-container.swal2-top-left {
  align-items: flex-start;
  justify-content: flex-start;
}
.swal2-container.swal2-top-end, .swal2-container.swal2-top-right {
  align-items: flex-start;
  justify-content: flex-end;
}
.swal2-container.swal2-center {
  align-items: center;
}
.swal2-container.swal2-center-start, .swal2-container.swal2-center-left {
  align-items: center;
  justify-content: flex-start;
}
.swal2-container.swal2-center-end, .swal2-container.swal2-center-right {
  align-items: center;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom {
  align-items: flex-end;
}
.swal2-container.swal2-bottom-start, .swal2-container.swal2-bottom-left {
  align-items: flex-end;
  justify-content: flex-start;
}
.swal2-container.swal2-bottom-end, .swal2-container.swal2-bottom-right {
  align-items: flex-end;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom > :first-child, .swal2-container.swal2-bottom-start > :first-child, .swal2-container.swal2-bottom-left > :first-child, .swal2-container.swal2-bottom-end > :first-child, .swal2-container.swal2-bottom-right > :first-child {
  margin-top: auto;
}
.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-self: stretch;
  justify-content: center;
}
.swal2-container.swal2-grow-row > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}
.swal2-container.swal2-grow-column {
  flex: 1;
  flex-direction: column;
}
.swal2-container.swal2-grow-column.swal2-top, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-bottom {
  align-items: center;
}
.swal2-container.swal2-grow-column.swal2-top-start, .swal2-container.swal2-grow-column.swal2-center-start, .swal2-container.swal2-grow-column.swal2-bottom-start, .swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-bottom-left {
  align-items: flex-start;
}
.swal2-container.swal2-grow-column.swal2-top-end, .swal2-container.swal2-grow-column.swal2-center-end, .swal2-container.swal2-grow-column.swal2-bottom-end, .swal2-container.swal2-grow-column.swal2-top-right, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-bottom-right {
  align-items: flex-end;
}
.swal2-container.swal2-grow-column > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}
.swal2-container.swal2-no-transition {
  transition: none !important;
}
.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen) > .swal2-modal {
  margin: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important;
  }
}

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 0.3rem;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
}
.swal2-popup:focus {
  outline: none;
}
.swal2-popup.swal2-loading {
  overflow-y: hidden;
}

.swal2-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.8em;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1.25em auto 0;
  padding: 0;
}
.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: 0.4;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: 0.25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent;
}

.swal2-styled {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  box-shadow: none;
  font-weight: 500;
}
.swal2-styled:not([disabled]) {
  cursor: pointer;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #007bff;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-deny {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #d14529;
  color: #fff;
  font-size: 1em;
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #6c757d;
  color: #fff;
  font-size: 1em;
}
.swal2-styled:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-styled::-moz-focus-inner {
  border: 0;
}

.swal2-footer {
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em;
}

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.25em;
  overflow: hidden;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.swal2-timer-progress-bar {
  width: 100%;
  height: 0.25em;
  background: rgba(0, 0, 0, 0.2);
}

.swal2-image {
  max-width: 100%;
  margin: 1.25em auto;
}

.swal2-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s ease-out;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: rgba(0, 0, 0, 0.5);
  font-family: serif;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
}
.swal2-close:hover {
  transform: none;
  background: transparent;
  color: #000;
}
.swal2-close:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-close::-moz-focus-inner {
  border: 0;
}

.swal2-content {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 0 1.6em;
  color: #545454;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}

.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 1em auto;
}

.swal2-input,
.swal2-file,
.swal2-textarea {
  box-sizing: border-box;
  width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  color: inherit;
  font-size: 1rem;
}
.swal2-input.swal2-inputerror,
.swal2-file.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #dc3545 !important;
  box-shadow: 0 0 2px #dc3545 !important;
}
.swal2-input:focus,
.swal2-file:focus,
.swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-input::placeholder,
.swal2-file::placeholder,
.swal2-textarea::placeholder {
  color: #cccccc;
}

.swal2-range {
  margin: 1em auto;
  background: #fff;
}
.swal2-range input {
  width: 80%;
}
.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center;
}
.swal2-range input,
.swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1rem;
  line-height: 2.625em;
}

.swal2-input {
  height: 2.625em;
  padding: 0.375rem 0.75rem;
}
.swal2-input[type=number] {
  max-width: 10em;
}

.swal2-file {
  background: inherit;
  font-size: 1rem;
}

.swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: 0.375em 0.625em;
  background: inherit;
  color: inherit;
  font-size: 1rem;
}

.swal2-radio,
.swal2-checkbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit;
}
.swal2-radio label,
.swal2-checkbox label {
  margin: 0 0.6em;
  font-size: 1rem;
}
.swal2-radio input,
.swal2-checkbox input {
  flex-shrink: 0;
  margin: 0 0.4em;
}

.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto;
}

.swal2-validation-message {
  align-items: center;
  justify-content: center;
  margin: 0 -2.7em;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666666;
  font-size: 1em;
  font-weight: 300;
}
.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}
.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #dc3545;
  color: #dc3545;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #dc3545;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
.swal2-icon.swal2-warning {
  border-color: #ffca2b;
  color: #ffc107;
}
.swal2-icon.swal2-info {
  border-color: #4cd3e9;
  color: #17a2b8;
}
.swal2-icon.swal2-question {
  border-color: #a1a8ae;
  color: #6c757d;
}
.swal2-icon.swal2-success {
  border-color: #28a745;
  color: #28a745;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
}
.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #28a745;
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: 0.8125em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}

.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 0 0 1.25em;
  padding: 0;
  background: inherit;
  font-weight: 600;
}
.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}
.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6;
}
.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: 0.4em;
  margin: 0 -1px;
  background: #2778c4;
}

[class^=swal2] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: fadeInDown 0.5s;
}

.swal2-hide {
  animation: fadeOutUp 0.5s;
}

.swal2-noanimation {
  transition: none;
}

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.swal2-rtl .swal2-close {
  right: auto;
  left: 0;
}
.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto;
}

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}
@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes swal2-show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}
body.swal2-height-auto {
  height: auto !important;
}
body.swal2-no-backdrop .swal2-container {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  max-width: calc(100% - 0.625em * 2);
  background-color: transparent !important;
}
body.swal2-no-backdrop .swal2-container > .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
body.swal2-no-backdrop .swal2-container.swal2-top {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-top-start, body.swal2-no-backdrop .swal2-container.swal2-top-left {
  top: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-top-end, body.swal2-no-backdrop .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-start, body.swal2-no-backdrop .swal2-container.swal2-center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-end, body.swal2-no-backdrop .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-start, body.swal2-no-backdrop .swal2-container.swal2-bottom-left {
  bottom: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-end, body.swal2-no-backdrop .swal2-container.swal2-bottom-right {
  right: 0;
  bottom: 0;
}
@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important;
  }
}
body.swal2-toast-shown .swal2-container {
  background-color: transparent;
}
body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

.swal2-confirm,
.swal2-cancel {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.swal2-popup {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.swal2-popup.swal2-toast {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
}
.swal2-popup.swal2-toast .swal2-header {
  padding: 0;
  border-bottom: 0;
}
.swal2-popup.swal2-toast .swal2-title {
  margin: 0;
  color: #6c757d;
}
.swal2-popup.swal2-toast .swal2-content {
  padding: 0 0.5rem;
}
.swal2-popup.swal2-toast .swal2-actions {
  flex-basis: 0 !important;
  margin: 0;
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-styled {
  margin: 0;
}

.swal2-title {
  margin: 0;
  font-size: 1.25rem;
}

.swal2-content {
  padding: 1rem 1rem 0;
}

.swal2-actions {
  border-radius: 0.25rem;
}

.swal2-footer {
  padding: 1rem;
  border-top: 1px solid #dee2e6;
}

.swal2-close {
  height: auto;
  padding: 1rem 1.2rem 1rem 1rem;
  font-weight: 700;
}

.swal2-input,
.swal2-textarea {
  height: inherit;
  padding: 0.375rem 0.75rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  line-height: 1.5;
}
.swal2-input:focus,
.swal2-textarea:focus {
  border: 1px solid #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  color: #495057;
}
.swal2-input.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  box-shadow: none !important;
}
.swal2-input.swal2-inputerror:focus,
.swal2-textarea.swal2-inputerror:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.swal2-styled.swal2-confirm {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
}
.swal2-styled.swal2-confirm:hover {
  border-color: #0062cc;
  background-color: #0069d9;
}
.swal2-styled.swal2-confirm:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.swal2-styled.swal2-confirm:active {
  border-color: #005cbf;
  background-color: #0062cc;
}
.swal2-styled.swal2-confirm:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.swal2-styled.swal2-cancel {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
}
.swal2-styled.swal2-cancel:hover {
  border-color: #545b62;
  background-color: #5a6268;
}
.swal2-styled.swal2-cancel:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}
.swal2-styled.swal2-cancel:active {
  border-color: #4e555b;
  background-color: #545b62;
}
.swal2-styled.swal2-cancel:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}

.swal2-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"%3e%3cpath fill="%23343a40" d="M2 0L0 2h4zm0 5L0 3h4z"/%3e%3c/svg%3e') no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  color: #495057;
  vertical-align: middle;
  appearance: none;
}
.swal2-select:focus {
  border: 1px solid #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.swal2-select:focus::-ms-value {
  background-color: #fff;
  color: #495057;
}
.swal2-select[multiple], .swal2-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.swal2-select:disabled {
  background-color: #e9ecef;
  color: #fff;
}
.swal2-select::-ms-expand {
  display: none;
}

.swal2-radio label {
  position: relative;
  margin-right: 1rem;
  margin-left: 1.5rem;
}
.swal2-radio label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.swal2-radio label input:checked ~ .swal2-label::before {
  border-color: #007bff;
  background: #007bff;
  color: #fff;
}
.swal2-radio label input:focus ~ .swal2-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.swal2-radio label input:focus:not(:checked) ~ .swal2-label::before {
  border-color: #80bdff;
}
.swal2-radio label input:not(:disabled):active ~ .swal2-label::before {
  border-color: #b3d7ff;
  background-color: #b3d7ff;
  color: #fff;
}
.swal2-radio label input:checked ~ .swal2-label::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"%3e%3ccircle r="3" fill="%23fff"/%3e%3c/svg%3e');
}
.swal2-radio label input:disabled:checked ~ .swal2-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.swal2-radio .swal2-label::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.0833333333rem;
  left: -1.5rem;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: #adb5bd solid 1px;
  border-radius: 50%;
  background-color: #fff;
  pointer-events: none;
}
.swal2-radio .swal2-label::after {
  content: "";
  display: block;
  position: absolute;
  top: 0.0833333333rem;
  left: -1.5rem;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: transparent solid 1px;
  background: no-repeat 50%/50% 50%;
}

.swal2-checkbox {
  margin-right: 1rem;
  padding-left: 1.5rem;
}
.swal2-checkbox input {
  z-index: -1;
  opacity: 0;
}
.swal2-checkbox input:checked ~ .swal2-label::before {
  border-color: #007bff;
  background: #007bff;
  color: #fff;
}
.swal2-checkbox input:focus ~ .swal2-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.swal2-checkbox input:focus:not(:checked) ~ .swal2-label::before {
  border-color: #80bdff;
}
.swal2-checkbox input:not(:disabled):active ~ .swal2-label::before {
  border-color: #b3d7ff;
  background-color: #b3d7ff;
  color: #fff;
}
.swal2-checkbox input:checked ~ .swal2-label::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8"%3e%3cpath fill="%23fff" d="M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z"/%3e%3c/svg%3e');
}
.swal2-checkbox input:disabled:checked ~ .swal2-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.swal2-checkbox .swal2-label {
  position: relative;
}
.swal2-checkbox .swal2-label::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: #adb5bd solid 1px;
  background-color: #fff;
  pointer-events: none;
}
.swal2-checkbox .swal2-label::after {
  content: "";
  display: block;
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: transparent solid 1px;
  background: no-repeat 50%/50% 50%;
}

.swal2-range {
  align-items: center;
}
.swal2-range input {
  width: 100%;
  height: calc(1rem + 0.6rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.swal2-range input:focus {
  outline: none;
}
.swal2-range input:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.swal2-range input:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.swal2-range input:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.swal2-range input::-moz-focus-outer {
  border: 0;
}
.swal2-range input::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 0;
  border-radius: 1rem;
  background: #007bff;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  appearance: none;
}
.swal2-range input::-webkit-slider-thumb:active {
  background: #b3d7ff;
}
.swal2-range input::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  border-radius: 1rem;
  border-color: transparent;
  background-color: #dee2e6;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  color: transparent;
  cursor: pointer;
}
.swal2-range input::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 0;
  border-radius: 1rem;
  background: #007bff;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  appearance: none;
}
.swal2-range input::-moz-range-thumb:active {
  background: #b3d7ff;
}
.swal2-range input::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  border-radius: 1rem;
  border-color: transparent;
  background-color: #dee2e6;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  color: transparent;
  cursor: pointer;
}
.swal2-range input::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 0;
  border-radius: 1rem;
  background: #007bff;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  appearance: none;
}
.swal2-range input::-ms-thumb:active {
  background: #b3d7ff;
}
.swal2-range input::-ms-track {
  width: 100%;
  height: 0.5rem;
  border-width: 0.5rem;
  border-color: transparent;
  background-color: transparent;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  color: transparent;
  cursor: pointer;
}
.swal2-range input::-ms-fill-lower {
  border-radius: 1rem;
  background-color: #dee2e6;
}
.swal2-range input::-ms-fill-upper {
  margin-right: 15px;
  border-radius: 1rem;
  background-color: #dee2e6;
}
.swal2-range input:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.swal2-range input:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.swal2-range input:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.swal2-range input:disabled::-moz-range-track {
  cursor: default;
}
.swal2-range input:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.swal2-validation-message {
  position: relative;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  border-color: #d6d8db;
  background: #e2e3e5;
  color: #383d41;
  font-size: 1rem;
}
.swal2-validation-message::before {
  background-color: #e4606d;
}

.swal2-toast {
  max-width: 350px;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.85);
}

.chart-container {
  position: relative;
  /* for absolutely positioned tooltip */
  /* https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/ */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.chart-container .axis, .chart-container .chart-label {
  fill: #555b51;
}
.chart-container .axis line, .chart-container .chart-label line {
  stroke: #dadada;
}
.chart-container .dataset-units circle {
  stroke: #fff;
  stroke-width: 2;
}
.chart-container .dataset-units path {
  fill: none;
  stroke-opacity: 1;
  stroke-width: 2px;
}
.chart-container .dataset-path {
  stroke-width: 2px;
}
.chart-container .path-group path {
  fill: none;
  stroke-opacity: 1;
  stroke-width: 2px;
}
.chart-container line.dashed {
  stroke-dasharray: 5, 3;
}
.chart-container .axis-line .specific-value {
  text-anchor: start;
}
.chart-container .axis-line .y-line {
  text-anchor: end;
}
.chart-container .axis-line .x-line {
  text-anchor: middle;
}
.chart-container .legend-dataset-text {
  fill: #6c7680;
  font-weight: 600;
}

.graph-svg-tip {
  position: absolute;
  z-index: 99999;
  padding: 10px;
  font-size: 12px;
  color: #959da5;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
}
.graph-svg-tip ul {
  padding-left: 0;
  display: flex;
}
.graph-svg-tip ol {
  padding-left: 0;
  display: flex;
}
.graph-svg-tip ul.data-point-list li {
  min-width: 90px;
  flex: 1;
  font-weight: 600;
}
.graph-svg-tip strong {
  color: #dfe2e5;
  font-weight: 600;
}
.graph-svg-tip .svg-pointer {
  position: absolute;
  height: 5px;
  margin: 0 0 0 -5px;
  content: " ";
  border: 5px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
}
.graph-svg-tip.comparison {
  padding: 0;
  text-align: left;
  pointer-events: none;
}
.graph-svg-tip.comparison .title {
  display: block;
  padding: 10px;
  margin: 0;
  font-weight: 600;
  line-height: 1;
  pointer-events: none;
}
.graph-svg-tip.comparison ul {
  margin: 0;
  white-space: nowrap;
  list-style: none;
}
.graph-svg-tip.comparison li {
  display: inline-block;
  padding: 5px 10px;
}

.form-control-custom {
  height: calc(1.5rem + 2px);
  background-color: #fff;
  color: #495057;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.form-control-custom:focus {
  border: 1px solid #80bdff;
  outline: 0;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

.dark-mode hr {
  border-color: #6c757d;
}
.dark-mode .form-control-custom {
  height: calc(1.5rem + 2px);
  background-color: #343a40;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
}
.dark-mode .form-control-custom:focus {
  border: 1px solid #80bdff;
  outline: 0;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}
.dark-mode .select2-selection {
  background: #454d55;
}
.dark-mode .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  color: #ced4c1;
}